@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --border-radius: var(--mui-shape-borderRadius);
  --border-color: var(--mui-palette-divider);
  --primary-color: var(--mui-palette-primary-main);
  --background-color: var(--mui-palette-background-default);
  --background-color-rgb: var(--mui-palette-background-paperChannel);
  --header-height: 54px;
  --header-z-index: var(--mui-zIndex-appBar);
  --footer-z-index: 10;
  --customizer-z-index: var(--mui-zIndex-drawer);
  --search-z-index: var(--mui-zIndex-tooltip);
  --drawer-z-index: var(--mui-zIndex-drawer);
  --backdrop-color: rgb(var(--mui-mainColorChannels-light) / 0.5);
}

[data-mui-color-scheme='dark'] {
  --backdrop-color: rgb(23 25 37 / 0.6);
}

*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT', currentColor);
}

html {
  display: flex;
  inline-size: 100%;
  min-block-size: 100%;
}

[data-mui-color-scheme='light'] body {
  background-color: var(--mui-palette-primary-lighterOpacity) !important;
}

a {
  color: inherit;
  text-decoration: none;
}

ul:not([class]) {
  margin-block: 1rem;
  padding-inline-start: 40px;
}

/*! Do note remove following style */
/*
  Fix the popout / submenu closing issue when using hover trigger to open the menu and using
  perfectscrollbar where the bridge create with safe polygon by floating ui was not working
*/
[data-floating-ui-safe-polygon] .scrollbar-container {
  pointer-events: auto;
}
[data-floating-ui-safe-polygon] .scrollbar-container .ps__rail-y {
  pointer-events: none;
}

.ps__rail-y {
  inset-inline-end: 0 !important;
  inset-inline-start: auto !important;
  & .ps__thumb-y {
    inset-inline-end: 3px !important;
    inset-inline-start: auto !important;
    background-color: var(--mui-palette-divider);
    inline-size: 6px;
    &:hover,
    &:focus,
    &.ps--clicking {
      background-color: var(--mui-palette-action-disabled) !important;
    }
  }
  &:hover,
  &:focus,
  &.ps--clicking {
    background-color: var(--mui-palette-action-hover) !important;
  }
  &:hover,
  &:focus,
  &.ps--clicking {
    .ps__thumb-y {
      background-color: var(--mui-palette-action-disabled) !important;
    }
  }
}

.ts-vertical-nav-root {
  .ps__thumb-y {
    inline-size: 4px;
    &:hover,
    &:focus,
    &.ps--clicking {
      inline-size: 6px;
    }
  }
  .ps__rail-y {
    inline-size: 10px;
    &:hover,
    &:focus,
    &.ps--clicking {
      background-color: transparent !important;
      .ps__thumb-y {
        inline-size: 6px;
      }
    }
  }
}

:where([class^='tabler-']) {
  font-size: 1.5rem;
}

code {
  font-family: inherit;
  padding-block: 2px;
  padding-inline: 4px;
  border-radius: 4px;
  font-size: 90%;
  color: var(--mui-palette-info-main);
  background-color: rgb(var(--mui-palette-info-mainChannel) / 0.08);
  border: 0;
}


.tableFixHead { height: 78vh; }
/* .tableFixHead thead th {position: sticky;  top: 0; } */

.tableFixHead thead th,
.tableFixHead tfoot tr {
  position: sticky;
  backdrop-filter: blur(20px);
}

.light thead th,
.light tfoot tr {
  background-color: #f8f7fa;
}

.dark thead th,
.dark tfoot tr {
  background-color: #25293c;
}

thead{
    border-block-start: none !important
}

thead th {
  top: 0;
  z-index: 999;
}

tfoot tr {
  bottom: 0
}
/* 
tbody{
  height: 65vh
} */

tbody tr:first-of-type td {
    padding-top:  10px;
  }
  
  tbody tr:last-of-type td {
    padding-bottom: 10px;
  }
/* tbody tr{
    overflow-y: auto;
} */
/* Scrollbar styles */
.scroller {  
  scrollbar-width: thin;
  scrollbar-color: #f5fbf7 #ffffff;
}

.scrollerdark {
  scrollbar-color: rgb(203 201 224 / 10%) #25293c;
  scrollbar-width: thin;
}

.MuiDialogTitle-root{
  padding: 0.5rem !important;
}

.react-datepicker-popper{
  z-index: 9999 !important;
}
